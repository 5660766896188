import './newygkzxcz.css'
import React, { Component } from 'react'
import { NEW_YGK_URL, YL_URL, PAGE_URL, CLYDD, Axios } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { NavBar, Icon, Card, WingBlank, WhiteSpace, Toast, List, Button, Modal, Radio, InputItem, SearchBar } from 'antd-mobile';
const RadioItem = Radio.RadioItem;
export default class Newygkzxcz extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            ygkxx: [],
            isLoading: false,
            modal: false,
            value: "",
            czje: "",
            item: "",
            ykth: "",
            isbr:"",
            jsrqFlag: true
        });
    }
    componentDidMount = () => {
        if (this.isWeiXin()) {
            this.setState({
                value:"WXPay.jsPay"
            });
        } else {
            this.setState({
                value:"trade.precreate"
            });
        }
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo == null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: CLYDD + "bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        yhid: userInfo.sfzh
                    })
                    this.getYgkxx(userInfo.sfzh, "");
                });
            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                yhid: userInfo.sfzh
            })
            this.getYgkxx(userInfo.sfzh, "");
        }
        // this.getYgkxx("612321199509106233","");
        this.getDate();
    }
    getDate() {
        var date = new Date();

        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        var today = date.getDate();
        var now = year + "-" + month + "-" + today;
        console.log(now);
        this.setState({
            nowdate: now,

        });

        const url = NEW_YGK_URL + 'whrqController/getJsrq';
        var params = new URLSearchParams();
        // params.append("sfzh","622323199208183117");

        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            Toast.hide();
            console.log(res);
            console.log(res.data.length);
            if (res.status === 200) {
                for (var a = 0; a < res.data.length; a++) {
                    var jsrq = res.data[a].jsrq;
                    if (jsrq.indexOf(now) > -1) {
                        console.log(111)
                        this.setState({
                            jsrqFlag: false,

                        });
                        console.log("jsrqFlag:",this.state.jsrqFlag);
                    }
                }
            }
        });
    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    async getYgkxx(sfzh, ykth) {
        const url = NEW_YGK_URL + 'bryxxController/getYgkxx';
        var params = new URLSearchParams();
        // params.append("sfzh","612321199509106233");
        params.append("sfzh", sfzh);
        params.append("ykth", ykth);
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            Toast.hide();
            if(res.data.flag){
                if (res.data.ygkxx.length > 0) {
                    this.setState({
                        ygkxx: res.data.ygkxx,
                        isbr: res.data.isbr,
                        isLoaded: true
                    });
                }
            }else{
                Toast.hide();
                Toast.info("未查到卡片信息，请联系管理员", 1, null, false);
            }

        });
    }
    handleOrder = (item) => {
        this.setState({
            modal: true,
            item: item
        });
    }
    // 获取选择车库信息
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    //下单
    setOrder = () => {
        if (this.state.jsrqFlag) {
            if (this.state.czje == '') {
                Toast.info("请输入充值金额", 1, null, false);
            } else if (this.state.value == '') {
                Toast.info("请选择支付类型", 1, null, false);
            } else {
                console.log(this.state.item.rybh);
                if (this.isWeiXin() && this.state.value == "trade.precreate") {
                    let openId = localStorage.getItem("openID");
                    //alert(openId);
                    window.parent.frames.location.href = PAGE_URL + "?openId=" + openId + "&page=newygkzxcz";
                    return;
                }
                // const url = YL_URL + 'xiadanController/order';
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                var url = "";

                if (this.isWeiXin()) {
                    url = YL_URL + 'xiadanWXController/order'
                } else {
                    url = YL_URL + 'xiadanController/order';
                }
                var params = new URLSearchParams();
                params.append('totalAmount', this.state.czje);
                params.append('goodsId', this.state.item.rybh);
                params.append('goodsName', "新一卡通在线充值");
                params.append('id', this.state.item.grzh);
                params.append('price', this.state.czje);
                params.append('payMethod', this.state.value);
                params.append('ywbm', "NEWYGKZXCZ");
                params.append('yhid', userInfo.sfzh);
                params.append('yhxm', userInfo.ryxm);
                Toast.loading("加载中...", 0, null, true);
                Axios({
                    method: 'post',
                    url: url,
                    data: params
                }).then((res) => {
                    Toast.hide();
                    if (this.state.value == "WXPay.jsPay") {
                        if (res.status == '200') {
                            window.parent.frames.location.href = res.data.data;
                        } else {
                            alert("支付失败，请重新支付");
                        }
                    } else {
                        if (res.status == '200') {
                            let jsonData = JSON.parse(res.data.data);
                            if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                                let payJson = JSON.parse(jsonData.respStr);
                                this.setState((state, props) => {
                                    return {
                                        zfdz: payJson.appPayRequest.qrCode,
                                        merOrderId: payJson.merOrderId
                                    }
                                },
                                    () => {
                                        //支付
                                        Modal.alert('确认支付？', '确认支付？', [
                                            { text: '取消', onPress: () => console.log('下次一定') },
                                            {
                                                text: '确定',
                                                onPress: () =>
                                                    new Promise((resolve) => {
                                                        this.setState({
                                                            isPay: true
                                                        });
                                                        //跳转到登录
                                                        window.open(this.state.zfdz);
                                                        this.props.history.push({ pathname: '/ygkjfqr', query: { merOrderId: this.state.merOrderId } });
                                                        setTimeout(resolve, 500);
                                                    }),
                                            },
                                        ])
                                    }
                                )

                            } else {
                                alert("支付失败，请重新支付");
                            }
                        }
                    }

                }).catch((e) => {
                    alert(JSON.stringify(e))
                })
            }
        }else{
            Toast.info("当前日期不能充值", 1, null, true);
        }
    }
    onSubmit = (e) => {
        if (e.length !== 8) {
            Toast.info("输入新一卡通号有误，请按照格式输入", 1, null, true);
        } else {
            this.setState({
                ykth: e
            });
            this.getYgkxx("", e);
        }

    };
    setyktxx = () => {

        if (this.state.isbr) {
            return this.state.ygkxx.map((item, key) => (
                <WingBlank size="lg" key={key}>
                    <WhiteSpace size="lg" />
                    <Card onClick={() => this.handleOrder(item)}>
                        <Card.Header
                            title={item.ryxm}
                            thumb={ygk}
                            extra={<span>{item.rybh}</span>}
                        />
                        <Card.Body>
                            <div>{item.zhlxmc}</div>
                        </Card.Body>
                        <Card.Footer content={'余额：' + item.ye / 100 + '元'} extra={<div>{'过渡金额：' + item.gdje / 100 + '元'}</div>} />
                    </Card>
                    <WhiteSpace size="lg" />
                </WingBlank>
            ))


        } else {
            return this.state.ygkxx.map((item, key) => (
                <WingBlank size="lg" key={key}>
                    <WhiteSpace size="lg" />
                    <Card onClick={() => this.handleOrder(item)}>
                        <Card.Header
                            title={item.ryxm.substring(0,1) + new Array(item.ryxm.length).join('*')}
                            thumb={ygk}
                            extra={<span>{item.rybh}</span>}
                        />
                        <Card.Body>
                            <div>{item.zhlxmc}</div>
                        </Card.Body>
                        <Card.Footer content={'过渡金额：' + item.gdje / 100 + '元'} />
                    </Card>
                    <WhiteSpace size="lg" />
                </WingBlank>
            ))
        }
    }
    render() {
        const { value } = this.state;
        const data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf }
        ];
        if (this.isWeiXin()) {
            var obj = {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            data.push(obj1);
        } else {
            var obj = {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >新一卡通在线充值</NavBar>
                <div className="kf">
                    <SearchBar placeholder="请输入代充值新一卡通号：卡片二维码下方数字" maxLength={8}
                        onSubmit={this.onSubmit}  cancelText={"查询"} onCancel={this.onSubmit}/>

                    <div className="gs" >*如需要为别人充值，请在上方输入相应新一卡通号</div>
                    {/* <div className="gs" >*为别人充值时，请仔细核对卡号，首字母必须大写</div> */}
                    {this.setyktxx()}
                    <div className="gs" >*每次充值完成后，下次消费时写卡至钱包</div>
                    <div className="gs" >*请勿将幼儿费充值到新一卡通中</div>
                </div>
                {/* 选择排号车库 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '充值金额'}>
                        <div className="gs" >*充值金额只能为整数</div>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.czje}
                            onChange={(value) => this.setState({ czje: value })}
                            extra="元"
                        >充值金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
            </div>
        )
    }
}
