import React, { Component } from 'react'
import { DOWNFILEURL, CLYDD, Axios, getUrlPackage } from '../../utils/url';
import { Grid, NavBar, Button } from 'antd-mobile';
import './yylb.css'

import Ygkgs from '../../assets/images/ygkgs.png'
import Ygkbk from '../../assets/images/ygkbk.png'
import Ygkjyjlcx from '../../assets/images/ygkjyjlcx.png'
import Ygkzxcz from '../../assets/images/ygkzxcz.png'

import Yktgbfjf from '../../assets/images/yktgbfjf.png'
import Yktjyjlcx from '../../assets/images/yktjyjlcx.png'
import Yktsfyz from '../../assets/images/yktsfyz.png'
import Yktsq from '../../assets/images/yktsq.png'
import Yktzxcz from '../../assets/images/yktzxcz.png'
import Yktgs from '../../assets/images/yktgs.png'
import Yktmjqxsq from '../../assets/images/ygkbk.png'
import Wyfjf from '../../assets/images/wyfjf.png'
import Yktbksq from '../../assets/images/yktbksq.png'
import Yktyj from '../../assets/images/yktyj.png'
import Dhfjf from '../../assets/images/dhfjf.png'
import Dhfcx from '../../assets/images/yktjyjlcx.png'
import Wyfcx from '../../assets/images/wyfcx.png'
import Appdown from '../../assets/images/80.png'
import Xgdh from '../../assets/images/xgdh.png'
import Dfshjf from '../../assets/images/dfshjf.png'
import Gdwxjf from '../../assets/images/gdjf.png'
import Down from '../../assets/images/download.png'
import head from '../../assets/images/head.jpg';
import Jdhc from '../../assets/images/jdhc.png';
import Jcyy from '../../assets/images/jcyy.png';

import Newygkzxcz from '../../assets/images/newygkzxcz.png'
import Newygkjyjl from '../../assets/images/newygkjyjl.png'
import Newygkzhxx from '../../assets/images/newygkzhxx.png'

const data1 = Array.from(new Array(9)).map(() => ({
    icon: Ygkzxcz,
}));
const ygkdata = [
    { icon: Ygkzxcz, text: "在线充值" },
    { icon: Ygkgs, text: "员工卡挂失" },
    { icon: Ygkbk, text: "员工卡补卡" },
    { icon: Ygkjyjlcx, text: "交易记录查询" }
];
//新员工卡
const newygkglydata = [
    { icon: Newygkzxcz, text: "在线充值" },
    { icon: Newygkjyjl, text: "交易记录查询" },
    { icon: Newygkzhxx, text: "账户信息查询" },
    { icon: Newygkzhxx, text: "卡片信息查询" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktgbfjf, text: "管理费(工本费)" },
    { icon: Yktsfyz, text: "C卡门禁延期" },
    { icon: Wyfjf, text: "预约申请" },
    { icon: Newygkzhxx, text: "预约确认" },
];
//新员工卡
const newygkdata = [
    { icon: Newygkzxcz, text: "在线充值" },
    { icon: Newygkjyjl, text: "交易记录查询" },
    { icon: Newygkzhxx, text: "账户信息查询" },
    { icon: Newygkzhxx, text: "卡片信息查询" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktgbfjf, text: "管理费(工本费)" },
    { icon: Yktsfyz, text: "C卡门禁延期" }
];
const yktdata = [
    { icon: Yktzxcz, text: "在线充值" },
    { icon: Yktsq, text: "一卡通申请" },
    { icon: Yktgs, text: "一卡通挂失" },
    { icon: Yktbksq, text: "一卡通补卡" },
    { icon: Yktsfyz, text: "一卡通门禁延期" },
    { icon: Yktgbfjf, text: "管理费(工本费)" },
    { icon: Yktyj, text: "一卡通押金" },
    { icon: Yktjyjlcx, text: "交易记录查询" },
    { icon: Yktmjqxsq, text: "门禁权限申请" },

];
const qtdata = [
    { icon: Jcyy, text: "就餐预约" },
];
const wyfdata = [
    // { icon: Dhfjf, text: "电话费缴费" },
    // { icon: Dhfcx, text: "电话费查询" },
    { icon: Wyfjf, text: "物业费缴费" },
    { icon: Wyfcx, text: "物业费查询" },
];
const appdata = [
    { icon: Appdown, text: "坦途移动办公" },
    { icon: Xgdh, text: "塔西南便民电话" },
    { icon: Dfshjf, text: "电费生活缴费" },
    { icon: Gdwxjf, text: "广电微信缴费" },
    { icon: Down, text: "公众号操作说明" },
    { icon: Jdhc, text: "京东慧采下载" },
];


export default class clgl extends Component {
    constructor(props) {
        super(props);
        this.state = ({ isgly: false, isVisible: true });
    }
    componentDidMount = () => {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: CLYDD + "bRyzcController/getUserInfo",
                    data: params,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.juggleManage(userInfo)
                });
            }
        } else {
            this.juggleManage(userInfo)
        }
    }
    juggleManage = (userInfo) => {
        const idList = ['653124199005282934', '622323199208183117', '612321199509106233', '612321199509106233', '652801199202025526', '652801199509105029']
        if (idList.includes(userInfo.sfzh)) {
            this.setState({ isgly: true });
        } else {
            this.setState({ isgly: false });
        }
    }
    openNewYgkGn = e => {
        if (e.text === "在线充值") {
            this.props.history.push('./Newygkzxcz');
        } else if (e.text === "交易记录查询") {
            this.props.history.push('./newygkjyjl');
        } else if (e.text === "账户信息查询") {
            this.props.history.push('./Newygkzhxx');
        } else if (e.text === "卡片信息查询") {
            this.props.history.push('./ScanQrCode');
        } else if (e.text === "一卡通挂失") {
            this.props.history.push('./lossReport');
        } else if (e.text === "一卡通补卡") {
            this.props.history.push('./replaceCard');
        } else if (e.text === "一卡通补卡申请") {
            this.props.history.push('./replaceCard');
        } else if (e.text === "管理费(工本费)") {
            this.props.history.push('./yktgbfjf');
        } else if (e.text === "C卡门禁延期") {
            this.props.history.push('./postpone');
        } if (e.text === "预约申请") {
            this.props.history.push('./YysqList');
        } if (e.text === "预约确认") {
            this.props.history.push('./YysqListQr');
        }
    }
    openYgkGn = e => {
        if (e.text === "员工卡补卡") {
            this.props.history.push('./ygkbksq');
        } else if (e.text === "交易记录查询") {
            this.props.history.push('./ygkjfjlcx');
        } else if (e.text === "在线充值") {
            this.props.history.push('./ygkzxcz');
        } else if (e.text === "员工卡挂失") {
            this.props.history.push('./ygkgs');
        }
    }
    openYktGn = e => {
        if (e.text === "一卡通申请") {
            this.props.history.push('./yktsq');
        } else if (e.text === "一卡通补卡") {
            this.props.history.push('./yktbksq');
        } else if (e.text === "管理费(工本费)") {
            this.props.history.push('./yktgbfjf');
        } else if (e.text === "交易记录查询") {
            this.props.history.push('./yktjfjlcx');
        } else if (e.text === "在线充值") {
            this.props.history.push('./yktzxcz');
        } else if (e.text === "一卡通门禁延期") {
            this.props.history.push('./yktsfyz');
        } else if (e.text === "一卡通挂失") {
            this.props.history.push('./yktgs');
        } else if (e.text === "一卡通押金") {
            this.props.history.push('./yktyjjf');
        } else if (e.text === "门禁权限申请") {
            this.props.history.push('./yktmjqxsq')
        }
    }
    openQtGn = e => {
        if (e.text === "就餐预约") {
            this.props.history.push('./jcfw')
        } if (e.text === "预约申请") {
            this.props.history.push('./YysqList');
        }
    }
    openWyfGn = e => {
        if (e.text === "物业费缴费") {
            this.props.history.push('./wyfjf');
        } else if (e.text === "物业费查询") {
            this.props.history.push('./wyfcx');
        } else if (e.text === "电话费缴费") {
            this.props.history.push('./dhfjf');
        } else if (e.text === '电话费查询') {
            this.props.history.push('./dhfcx');
        }
    }
    openAppGn = e => {
        if (e.text === "坦途移动办公") {
            this.props.history.push('./appdown');
        } else if (e.text === '塔西南便民电话') {
            this.props.history.push('./xgdh');
        } else if (e.text === '电费生活缴费') {
            window.open(DOWNFILEURL + 'yktsqxx/download?' + getUrlPackage('fjlx=dfshjffj'))
        } else if (e.text === '广电微信缴费') {
            window.open(DOWNFILEURL + 'yktsqxx/download?' + getUrlPackage('fjlx=gdwxjffj'))
        } else if (e.text === '公众号操作说明') {
            window.open(DOWNFILEURL + 'yktsqxx/download?' + getUrlPackage('fjlx=gzhczsmfj'))
        } else if (e.text === '京东慧才下载') {
            this.props.history.push('./jdhcdown');
        }
    }
    openDownload = e => {
        if (e.text === "一卡通申请单") {
            window.open(DOWNFILEURL + 'yktsqxx/download?' + getUrlPackage('fjlx=yktsqfj'))
        }
    }
    openFwcz = () => {
        var openid = localStorage.getItem("openID");
        window.open("https://tlm.wx.api.etvcsoft.com/index.html#/home?id=" + openid)
    }
    handleClose = () => {
        this.setState({
            isVisible: false
        });
    }
    getCardDom = (name, itemList, clickFun) => {
        return <div className="yylb yybl">
            <div className="grid-css-child"><span style={{ color: "rgba(227,87,73)" }}>┃</span>{name}</div>
            {/* <Grid data={itemList} activeStyle={false} hasLine={false} onClick={this.openWyfGn}/>  */}
            <Grid data={itemList} onClick={clickFun} activeStyle={false} hasLine={false} itemStyle={{ height: '120px' }}
                columnNum={4}
                renderItem={dataItem => (
                    <div style={{ paddingTop: "12px" }}>
                        <img src={dataItem.icon} style={{ width: '50px', height: '50px' }} alt="" />
                        <div style={{ fontSize: '12px', paddingTop: "12px" }}>
                            <span>{dataItem.text}</span>
                        </div>
                    </div>
                )}
            />
        </div>
    }
    getAntiFraudDom = () => {
        return <div id='antiFraudModel' style={{
            zIndex: "2147483584", position: "fixed", top: "10%", left: "10%", width: "80%", height: "80%", display: this.state.isVisible ? 'block' : 'none'
        }}>
            <button onClick={this.handleClose} style={{
                width: '40px', height: '40px', display: "block", fontSize: '24px', cursor: 'pointer',
                position: 'absolute', top: '0', right: '0', color: 'red', fontWeight: 'bold', borderStyle: 'hidden',
                backgroundColor: 'rgba(87,91,222,0)', borderColor: 'rgba(87,91,222,0)'
            }}>X</button>
            <img src="asjkdhfkljas.png" alt='反诈照片' style={{ width: "100%", height: '100%' }} />
        </div>
    }
    isglyjm = () => {
        if (this.state.isgly) { // .89rem
            return <div style={{ marginTop: "0" }}>
                <div style={{ paddingBottom: "100px" }}>
                    <img src={head} className="jubao1" style={{ width: "100%" }}></img>
                    {this.getCardDom('  收费业务', wyfdata, this.openWyfGn)}
                    {this.getCardDom('  新一卡通', newygkglydata, this.openNewYgkGn)}
                    {/* {this.getCardDom('  员工卡', ygkdata, this.openYgkGn)} */}
                    {/* {this.getCardDom('  一卡通', yktdata, this.openYktGn)} */}
                    {/* {this.getCardDom('  其他业务', qtdata, this.openQtGn)} */}
                    {this.getCardDom('  相关下载', appdata, this.openAppGn)}
                    <div className="yylb yybl grid-css-child">技术支持电话:
                        <div style={{ paddingLeft: "20px", display: "inline-block" }}><a href="tel:18130805666">18130805666</a></div>
                    </div>
                    {this.getAntiFraudDom()}
                </div>
            </div>
        } else {
            return <div style={{ marginTop: ".89rem" }}>
                <div style={{ paddingBottom: "100px" }}>
                    <img src={head} className="jubao1" style={{ width: "100%" }}></img>
                    {this.getCardDom('  收费业务', wyfdata, this.openWyfGn)}
                    {this.getCardDom('  新一卡通', newygkdata, this.openNewYgkGn)}
                    {/* {this.getCardDom('  员工卡', ygkdata, this.openYgkGn)} */}
                    {/* {this.getCardDom('  一卡通', yktdata, this.openYktGn)} */}
                    {/* {this.getCardDom('  其他业务', qtdata, this.openQtGn)} */}
                    {this.getCardDom('  相关下载', appdata, this.openAppGn)}
                    <div className="yylb yybl grid-css-child">技术支持电话:
                        <div style={{ paddingLeft: "20px", display: "inline-block" }}><a href="tel:18130805666">18130805666</a></div>
                    </div>
                    {this.getAntiFraudDom()}
                </div>
            </div>
        }
    }
    render() {
        return this.isglyjm()
    }
}
