import axios from 'axios';

export const JC_URL = process.env.REACT_APP_JCURL
export const BASE_URL = process.env.REACT_APP_URL
export const NEW_YGK_URL = process.env.REACT_APP_NEWYGKURL

export const CLYDD = process.env.REACT_APP_CLYDD
export const YGK_URL = process.env.REACT_APP_YGKURL
export const YL_URL = process.env.REACT_APP_YLURL
export const YKT_URL = process.env.REACT_APP_YKTURL
export const PAGE_URL = process.env.REACT_APP_PAGEURL
export const DOWNFILEURL = process.env.REACT_APP_DOWNFILEURL
export const TTAZURL = process.env.REACT_APP_TTAZURL

export const FORMAT = {//金额数字格式化
  number_format: function (number, decimals, dec_point, thousands_sep) {
    /*
       * 参数说明：
       * number：要格式化的数字
       * decimals：保留几位小数
       * dec_point：小数点符号
       * thousands_sep：千分位符号
       * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.ceil(n * k) / k;
      };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
};

axios.interceptors.request.use(config => {
  const { headers, data, url, params } = config

  const publicKey = localStorage.getItem('publicKey')

  if ((params || url.includes('?')) && !juggleHas(url, '19091')) {
    let queryList = []
    if (url.includes('?') && url.split('?')[1]) {
      queryList.push(url.split('?')[1])
    }
    if (params) {
      for (const key in params) {
        queryList.push(key + '=' + params[key])
      }
    }

    config.params = {
      querySecretPackage: new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(queryList.join('&')), publicKey)
    }
    config.url = config.url.split('?')[0]
  }

  if (data && !juggleHas(url, '19091')) {
    //数据加密
    if (data instanceof URLSearchParams) {
      const encryptParams = new URLSearchParams
      const json = {}
      for (const [key, value] of data) {
        json[key] = value
      }
      encryptParams.append('secretPackage', new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(json)), publicKey))
      config.data = encryptParams
    } else if (data instanceof FormData) {
      const json = {}
      const formData = new FormData()
      for (const [key, val] of data.entries()) {
        if (val instanceof File) {
          formData.append(key, val);
        } else {
          json[key] = val
        }
      }
      formData.append('secretPackage', new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(json)), publicKey));
      config.data = formData
    } else {//json类型数据加密
      config.data = {
        secretPackage: new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(data)), publicKey)
      }
    }
  }
  return config
}, error => Promise.reject(error));

function juggleHas(value, target) {
  if (undefined == target || null == target) return false
  if (!(undefined == value || null == value) && 'string' === typeof (value)) {
    return value.indexOf(target) > -1
  } else if (!(undefined == value || null == value)) {
    return JSON.stringify(value).indexOf(target) > -1
  }
  return false
}

export const Axios = axios

export const getUrlPackage = (value) => {
  if (value) {
    return `querySecretPackage=${new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(value), localStorage.getItem('publicKey'))}`
  }
  return ''
}