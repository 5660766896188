import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './index.css';
import { BASE_URL, CLYDD, Axios } from './utils/url'
import "antd-mobile/dist/antd-mobile.css"
import './assets/fonts/iconfont.css'
//添加全局黑白色调
//import './assets/fonts/site.css'
//我们自己写的样式导入在组件库后面才能有效果
import './index.css';
window.addEventListener(
    'hashchange',
    function (event) {
        window.scrollTo(0, 0)
    },
    false
)

// 解析url参数
const url = window.location.href
if (url.includes('?')) {
    const paramsStr = url.split('?')[1]
    if (paramsStr.includes('&') || paramsStr.includes('=')) {
        for (const item of paramsStr.split('&')) {
            const paramsValue = item.split('=')
            localStorage.setItem(paramsValue[0], paramsValue[1])
        }
    }
}
localStorage.setItem('openID', localStorage.getItem('openid'))

// 获取公钥 用户信息
Axios({ method: 'get', url: BASE_URL + "getPublicKey" }).then((result) => {
    if (result.data.flag) {
        localStorage.setItem("publicKey", result.data.data);
    }
    if (localStorage.getItem('openid')) {
        var params = new URLSearchParams();
        params.append('openid', localStorage.getItem("openid"));
        Axios({
            method: 'post',
            url: CLYDD + "bRyzcController/getUserInfo",
            data: params
        }).then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
            localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
            const idCardList = ['682801199903014539', '653124199905282934', '653124199005282934']
            if (res && res.data && res.data.userInfo && idCardList.includes(res.data.userInfo.sfzh)) {
                creatApp()
            } else {
                // alert('应用调试中···' + JSON.stringify(res))
                creatApp()
            }
        }).catch(error => {
            alert(JSON.stringify(error))
        }).finally(() => {});
    } else {
        // creatApp()
        alert('未接收到微信用户ID！')
    }
});

function creatApp() {
    ReactDOM.render(
        // <React.StrictMode>
        <App />,
        // </React.StrictMode>,
        document.getElementById('root')
    );
}