import React from 'react'
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import './utils/url.js'
import Login from './pages/login'
import Main from "./pages/main"
import Zc from './pages/zc'
import Appdown from './pages/appdown'
import Jdhcdown from './pages/jdhcdown'
import Jfqr from './pages/jfqr'
import Ygkbksq from './pages/ygkyw/ygkbksq'
import Ygkgs from './pages/ygkyw/ygkgs'
import Ygkjfjlcx from './pages/ygkyw/ygkjfjlcx'
import Ygkzxcz from './pages/ygkyw/ygkzxcz'
import Yktgbfjf from './pages/yktyw/yktgbfjf'
import Yktgs from './pages/yktyw/yktgs'
import Yktjfjlcx from './pages/yktyw/yktjfjlcx'
import Yktqxxxcx from './pages/yktyw/yktqxxxcx'
import Yktsfyz from './pages/yktyw/yktsfyz'
import Yktsq from './pages/yktyw/yktsq'
import Yktzxcz from './pages/yktyw/yktzxcz'
import Ygkjfqr from './pages/ygkyw/ygkjfqr'
import Ygkbkedit from './pages/ygkyw/ygkgbedit'
import Ygkgbshow from './pages/ygkyw/ygkgbshow'
import Yktsqedit from './pages/yktyw/yktsqedit'
import Yktsqshow from './pages/yktyw/yktsqshow'
import Yktsfyzedit from './pages/yktyw/yktsfyzedit'
import Yktsfyzshow from './pages/yktyw/yktsfyzshow'
import Yktyjjfqr from './pages/yktyw/yktyjjfqr'
import Yktjfqr from './pages/yktyw/yktjfqr'
import Yktmjqxsq from './pages/yktyw/yktmjqxsq'
import Yktmjqxsqshow from './pages/yktyw/yktmjqxsqshow'
import YktmjqxsqEdit from './pages/yktyw/yktmjqxsqedit'
import Wyfjf from './pages/wysf/wyfjf'
import Wyfcx from './pages/wysf/wyfcx'
import Yktbksq from './pages/yktyw/yktbksq'
import Yktgbshow from './pages/yktyw/yktgbshow'
import Yktgbedit from './pages/yktyw/yktgbedit'
import Yktgbfjfqr from './pages/yktyw/yktgbfjfqr'
import Yktyjjf from './pages/yktyw/yktyjjf'
import Wyfjfqr from './pages/wysf/wyfjfqr'
import Wyfcxmx from './pages/wysf/wyfcxmx'
import Dhfjf from './pages/wysf/dhfjf'
import Dhfjfqr from './pages/wysf/dhfjfqr'
import Dhfcx from './pages/wysf/dhfcx'
import Dhfcxmx from './pages/wysf/dhfcxmx'
import Wjmm from './pages/wjmm'

import fwxxbd from './pages/grzx/fwbd/index.js'
import fwxxbdlist from './pages/grzx/fwbd/fwbdlist.js'
import jfjl from './pages/grzx/jfjl/index.js'
import xggrxx from './pages/grzx/xxxg/index.js'
import sysm from './pages/grzx/sysm/index.js'
import xgdh from './pages/xgdh/xgdh.js'

// 就餐模块
import Jcfw from './pages/jcfw'
import Jcsq from './pages/jcfw/jcsq'
import Jccx from './pages/jcfw/jccx'
import Jcqx from './pages/jcfw/jcqx'

import Newygkjyjl from './pages/newYgkyw/newygkjyjl'
import Newygkzhxx from './pages/newYgkyw/newygkzhxx'
import Newygkzxcz from './pages/newYgkyw/newygkzxcz'
import ScanQrCode from './pages/newYgkyw/scanQrCode'
import LossReport from './pages/newYgkyw/lossReport'
import ReplaceCard from './pages/newYgkyw/replaceCard'
import ReplaceCardSubmit from './pages/newYgkyw/replaceCardSubmit'
import ReplaceCardInfoShow from './pages/newYgkyw/replaceCardInfoShow'
import Postpone from './pages/newYgkyw/postpone'
import PostponeApply from './pages/newYgkyw/postponeApply'
import PostponeShow from './pages/newYgkyw/postponeShow'

//预约申请
import YysqList from './pages/yysq/yysqlist'
import Yysq from   './pages/yysq/yysq/yysqUpdate.js'
import YysqView from   './pages/yysq/yysq/yysqView.js'

import YysqListQr from './pages/yysq/yysqlistqr'
import YysqViewQr from   './pages/yysq/yysqqr/yysqViewQr.js'
function App() {
  return (
    <Router>
      <div >
        {/* 默认配置路由 */}
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/wjmm" component={Wjmm} />
        <Route path="/login" component={Login} />
        <Route path="/zc" component={Zc} />
        <Route path="/main" component={Main} />
        <Route path="/jfqr" component={Jfqr} />

        {/* 新一卡通 */}
        <Route path="/newygkjyjl" component={Newygkjyjl} />
        <Route path="/newygkzhxx" component={Newygkzhxx} />
        <Route path="/newygkzxcz" component={Newygkzxcz} />
        <Route path="/scanQrCode" component={ScanQrCode} />
        <Route path="/lossReport" component={LossReport} />
        <Route path="/replaceCard" component={ReplaceCard} />
        <Route path="/replaceCardInfoShow" component={ReplaceCardInfoShow} />
        <Route path="/replaceCardSubmit" component={ReplaceCardSubmit} />  
        <Route path="/postpone" component={Postpone} />
        <Route path="/postponeApply" component={PostponeApply} />
        <Route path="/postponeShow" component={PostponeShow} />      

        {/* 员工卡 */}
        <Route path="/ygkbksq" component={Ygkbksq} />
        <Route path="/ygkgs" component={Ygkgs} />
        <Route path="/ygkjfjlcx" component={Ygkjfjlcx} />
        <Route path="/ygkzxcz" component={Ygkzxcz} />
        <Route path="/ygkjfqr" component={Ygkjfqr} />
        <Route path="/ygkgbedit" component={Ygkbkedit} />
        <Route path="/ygkgbshow" component={Ygkgbshow} />


        {/* 一卡通 */}
        <Route path="/yktgbfjf" component={Yktgbfjf} />
        <Route path="/yktgs" component={Yktgs} />
        <Route path="/yktjfjlcx" component={Yktjfjlcx} />
        <Route path="/yktqxxxcx" component={Yktqxxxcx} />
        <Route path="/yktsfyz" component={Yktsfyz} />
        <Route path="/yktsq" component={Yktsq} />
        <Route path="/yktzxcz" component={Yktzxcz} />
        <Route path="/yktjfqr" component={Yktjfqr} />
        <Route path="/yktbksq" component={Yktbksq} />
        <Route path="/yktgbshow" component={Yktgbshow} />
        <Route path="/yktgbedit" component={Yktgbedit} />
        <Route path="/yktsfyzedit" component={Yktsfyzedit} />
        <Route path="/yktsfyzshow" component={Yktsfyzshow} />
        <Route path="/yktsqedit" component={Yktsqedit} />
        <Route path="/yktsqshow" component={Yktsqshow} />
        <Route path="/yktyjjf" component={Yktyjjf} />
        <Route path="/yktyjjfqr" component={Yktyjjfqr} />
        <Route path="/yktmjqxsq" component={Yktmjqxsq} />
        <Route path="/yktmjqxsqshow" component={Yktmjqxsqshow} />
        <Route path="/yktmjqxsqedit" component={YktmjqxsqEdit} />

         {/* 预约申请 */}
         <Route path="/YysqList" component={YysqList} />
        <Route path="/Yysq" component={Yysq} />
        <Route path="/YysqView" component={YysqView} />

         {/* 预约确认 */}
         <Route path="/YysqListQr" component={YysqListQr} />
         <Route path="/YysqViewQr" component={YysqViewQr} />
        
        {/* 就餐功能 */}
        <Route path="/jcfw" component={Jcfw} />
        <Route path="/jccx" component={Jccx} />
        <Route path="/jcqx" component={Jcqx} />
        <Route path="/jcsq" component={Jcsq} />
        {/* 物业 */}
        <Route path="/wyfjf" component={Wyfjf} />
        <Route path="/wyfcx" component={Wyfcx} />

        <Route path="/yktgbfjfqr" component={Yktgbfjfqr} />
        <Route path="/wyfjfqr" component={Wyfjfqr} />
        <Route path="/wyfcxmx" component={Wyfcxmx} />
        <Route path="/dhfjf" component={Dhfjf} />
        <Route path="/dhfjfqr" component={Dhfjfqr} />
        <Route path="/dhfcx" component={Dhfcx} />
        <Route path="/dhfcxmx" component={Dhfcxmx} />

        {/* 个人主页 */}
        <Route path="/jfjl" component={jfjl} />
        <Route path="/xggexx" component={xggrxx} />
        <Route path="/fwxxbd" component={fwxxbd} />
        <Route path="/fwxxbdlist" component={fwxxbdlist} />
        <Route path="/sysm" component={sysm} />
        <Route path="/xgdh" component={xgdh} />
        {/* 相关下载 */}
        <Route path="/appdown" component={Appdown} />
        <Route path="/jdhcdown" component={Jdhcdown} />
      </div>


    </Router>
  );
}

export default App;
