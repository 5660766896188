import './lossReport.css'
import React, { Component } from 'react'
import { NEW_YGK_URL, CLYDD, Axios } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png';
import { FORMAT } from '../../../utils/url';
import { NavBar, Icon, List, Toast, Modal,Button ,WingBlank,WhiteSpace,Card} from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;

export default class LossReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            idCardNo:'',
            modal2: false,
            personList:[],
            text: "",
            packageTypeList: [{ value: 0, label: '' },
            { value: 1, label: '' }],
            dqzt:""
        }
    };
    //进页面加载
    componentDidMount = () => {
        //页面加载是获取登录用户的人员信息
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userInfo);
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: CLYDD + "bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        idCardNo: userInfo.sfzh
                    })
                    this.getPackageTypeList(userInfo.sfzh)
                });
            }
        } else {
            this.setState({
                idCardNo: userInfo.sfzh
            })
            this.getPackageTypeList(userInfo.sfzh)
        }

        this.getPackageTypeList(userInfo.sfzh);
        // this.getPackageTypeList('612321199509106233');
    }
    //获取个人账户列表及人员信息
    async getPackageTypeList (value){
        const url = NEW_YGK_URL + "consumeaccount/getPersonAccountByIdCardNo";
        var params = new URLSearchParams();
        params.append('idCardNo', value);
        await Axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((resolve) =>{
            if(resolve.data.flag){
                console.log(resolve.data.data);
                const data = resolve.data.data;
                this.setState({
                    packageTypeList: data.accountList,
                    personList: data.personInfo,
                    isLoaded: true,
                    dqzt: data.accountList[0].STATUS
                })
            }else{
                Toast.info("没有获取到账户信息")
            }
        },reject =>{
            console.log(reject);
        })

    }
    SetMapDw = (e) => {
        console.log(this.state.packageTypeList)
        if (this.state.packageTypeList.length > 0) {
            return this.state.packageTypeList.map((item, key) => (
                <Item multipleLine extra={item.ACCOUNT_TYPE_NAME} key={key} >
                    余额：{FORMAT.number_format(item.BALANCE/100, 2)+"元"}
                    <Brief>状态：{item.STATUS}</Brief>  <Brief>过度金额：{FORMAT.number_format(item.TRANSIT_MONEY/100,2)+"元"}</Brief>
                </Item>
            ))
        }
        this.forceUpdate()
    }
    //挂失方法
     lossReport(){
        const {idCardNo,dqzt} = this.state;
        alert(idCardNo);
        if(dqzt === '挂失'){
            Modal.alert('已挂失请勿重复操作','',[
                {text:'取消',
                onPress:()=> new Promise(resolve=>{
                this.componentDidMount();
                setTimeout(resolve, 100);
            })}])
            return null;
        }
        const url = NEW_YGK_URL + "consumeaccount/LossReportConsumeAccount";
        var params = new URLSearchParams();
        params.append('idCardNo', idCardNo);
        Modal.alert('您确定挂失吗','挂失后只能去大厅去补卡',[
            {text: '取消', onPress: () => new Promise(resolve =>{
                this.componentDidMount();
                setTimeout(resolve, 100);
            })},{
                text: '确定',
                onPress: ()=> new Promise(resolve=>{
                    setTimeout(resolve, 1000);
                    Axios({
                        method: 'post',
                        url: url,
                        data: params
                    }).then((resolve) =>{
                        Toast.hide();
                        this.setState({
                            modal2:false,
                            dqzt: false,
                        },()=>{
                            this.onClose('modal2');
                            Modal.alert('挂失成功','',[
                                {text:'取消',onPress: ()=>new Promise(resolve=>{
                                    this.componentDidMount();
                                    setTimeout(resolve, 100);
                                }),}
                            ])
                        })
                    })
                })
            }
        ])
    }
    //挂失按钮
    setButton = (e) => {
            return (
                <List.Item>
                    <Button type="primary" onClick={this.lossReport.bind(this)}>挂失</Button>
                </List.Item>
            )
    }
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.personList.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={()=>this.handleJump(item)}>
                    <Card.Header
                        title={item.PERSON_NAME}
                        thumb={ygk}
                        extra={<span>{item.HR_NO}</span>}
                    />
                    <Card.Body>
                    </Card.Body>
                    <Card.Footer content={'联系手机号：' + item.PHONE_NO }  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }
    handleJump = (item) => {
        this.setState({
            modal2: true,
        });
    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        })

    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >一卡通挂失</NavBar>
                <div className="divmagin">
                    {this.setMapList()}
                </div>
                <div>
                    <Modal
                        popup
                        visible={this.state.modal2}
                        onClose={this.onClose('modal2')}
                        animationType="slide-up"
                        style={{
                            height: "520px",
                            overflow: "auto"
                        }}
                    >
                        <List renderHeader={() => <div>一卡通明细</div>} className="popup-list">
                            {this.SetMapDw(this.state.text)}
                            {this.setButton()}
                        </List>
                    </Modal>
                </div>
            </div>
        )
    }
}
